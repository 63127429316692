icon-container {
    display: flex;
}

.icon {
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
}

.selected {
    color: blue;
    /* Change color to blue when selected */
}