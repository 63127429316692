.skills-container {
    display: flex;
    flex-wrap: wrap;
    /* Allow skills to wrap to the next line */
}

.skill {
    margin-right: 10px;
    /* Add margin between skills */
    margin-bottom: 10px;
    /* Add margin below each row of skills */
}

.skill button {
    margin-left: 5px;
    /* Add margin between skill text and remove button */
}

.chip {
    display: inline-flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 16px;
    padding: 4px 8px;
    margin-right: 8px;
    margin-bottom: 8px;
}

.remove-button {
    margin-left: 4px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #666;
}

.remove-button:hover {
    color: #333;
}

