/*================================================
Template name: Travgo Travel Mobile App Template
Version: 1.0.0
Author: Website Stock       
Author url: https://themeforest.net/user/website_stock/portfolio  

[ Table of Contents ]

01: loading-spinner

==================================================*/

/*===========================================================
01: loading-spinner CSS
=============================================================*/

@keyframes loader-spinner {
  0% {
      opacity: 1;
  }

  100% {
      opacity: 0;
  }
}