.reviewwrapper {
    display: flex;
 
justify-content: cn;

    gap: 20px;

}

.review-card {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.img-area {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 25px;
}

.img-area img {
    width: 100%;
}

.header-content_review {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.inforeview h4 {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
}

.inforeview p {
    margin: 5px 0 0;
    font-size: 14px;
    font-weight: 400;
    color: #666;
}

.single-review p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.7;
    color: #333;
}

.review-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
    color: #666;
}

.rating-review {
    display: flex;
}

.rating-review span {
    color: #000;
    font-size: 18px;
}

.rating span.active {
    color: #fbc02d;
}

@media screen and (max-width: 767px) {
    .wrapper {
        flex-direction: column;
        align-items: center;
    }

    .review-card {
        width: 85%;
    }
}