.custom-buttonswitch {
    padding: 10px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 10px;
    outline: none;
    margin: 5px;
   display: flexbox;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-buttonswitch:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.custom-buttonswitch:active {
    transform: translateY(1px);
}

/* Style for active button */
.activeswitch {
    background-color: #0056b3;
}

