


 .kyc-center {
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .kyc-image-text-container {
     display: flex;
     align-items: center;
 }

 .kyc-card {
    width: 100%;
    
     margin-bottom: 20px;
     border: 1px solid;
     border-color: #c9cdd1;
     /* Add space between cards */
     border-radius: 10px;
 }

 .btn-kyc {
     background-color: white;
     border: 1px solid #878b8f;
     /* Primary color */
     border-radius: 20px;
     /* Adjust border radius as needed */
     color: #007bff;
     /* Primary color */
     transition: all 0.3s ease-in-out;
 }

 .btn-kyc:hover {
     background-color: #d3dce6;
     /* Primary color */
     color: white;
 }