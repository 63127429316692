/*================================================
Template name: Travgo Travel Mobile App Template
Version: 1.0.0
Author: Website Stock       
Author url: https://themeforest.net/user/website_stock/portfolio  

[ Table of Contents ]

01: home CSS
02: info CSS
03: service CSS
04: visited CSS
05: budget CSS
06: service-location CSS
07: tour-guide CSS
08: dark-mode CSS

==================================================*/
/*===========================================================
01: home CSS
=============================================================*/
.list-container {
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-container h4,
.list-container p,
.list-container span {
  margin: 0;
}

.home {
  padding: 16px 24px;
  margin-bottom: 70px;
  margin-top: 55px;
}

.home .title {
  padding-bottom: 16px;
}

.home .title h2 {
  font-weight: 700;
  font-size: 18px;
  color: var(--primary-text);
}

.home .title a {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary);
}

/*===========================================================
02: info CSS
=============================================================*/
.info .image {
  height: 40px;
  width: 40px;
}

.info h3 {
  font-size: 18px;
  font-weight: 700;
  color: var(--primary-text);
}

.info p {
  font-size: 12px;
  color: var(--secondary-text);
}

.info ul a,
.info ul button {
  height: 46px;
  width: 46px;
}

.info ul a span,
.info ul button span {
  /* display: inline-block; */
  /* height: 11px; */
  /* width: 11px; */
  border-radius: 50%;
  /* background: var(--alert); */
  position: absolute;
  /* top: 11px; */
  /* right: 11px; */
  z-index: 10;
}

/*===========================================================
03: service CSS
=============================================================*/
.service {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.serviceModal .modal-body {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 32px;
  column-gap: 14px;
}

.serviceModal .item .image,
.service .item .image {
  height: 64px;
  width: 64px;
  background: var(--secondary);
}

.serviceModal .item figcaption,
.service .item figcaption {
  margin-top: 11px;
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-text);
}

/*===========================================================
04: visited CSS
=============================================================*/
.visited .visited-pagination {
  position: static;
  text-align: right;
}

/*===========================================================
05: budget CSS
=============================================================*/
.budget ul li:not(:last-child) {
  margin-bottom: 16px;
}

.budget .image {
  width: 86px;
  height: 86px;
}

.budget .content h4 {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-text);
}

.budget .content h5 {
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-text);
  padding-top: 4px;
  padding-bottom: 12px;
}

.budget .content .price,
.budget .content .location {
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-text);
}

.budget .content .price span {
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-text);
}

/*===========================================================
06: service-location CSS
=============================================================*/
.map {
  height: 100vh;
  width: 100%;
}

.locationModal {
  backdrop-filter: blur(0);
}

.locationModal .modal-body {
  display: block;
  padding: 0 24px 24px 24px;
  max-height: 270px;
  overflow-y: auto;
}

.locationModal .modal-body .item-list li:not(:last-child) {
  margin-bottom: 16px;
}

.locationModal .item .image {
  width: 92px;
  height: 92px;
}

.locationModal .content h4 {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-text);
}

.locationModal .content .rating {
  font-size: 12px;
  font-weight: 600;
  color: var(--warning);
}

.locationModal .content .rating span {
  color: var(--secondary-text);
}

.locationModal .content .location {
  color: var(--gray-90);
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
}

.locationModal2 .modal-dialog {
  margin: 24px;
  max-width: 100%;
  display: flex;
  align-items: flex-end;
  border-radius: 16px;
  height: calc(100vh - 48px);
}

.locationModal2 .modal-body {
  padding: 24px;
}

.locationModal2 .item .image {
  width: 80px;
  height: 100px;
}

.locationModal2 .btn-list a {
  height: 50px;
  width: 50px;
  background: var(--secondary);
}

.locationModal2 .btn-list a.active {
  background: var(--primary);
}

.locationModal2 .btn-list a.active img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(139deg) brightness(103%) contrast(101%);
}

/*===========================================================
07: tour-guide CSS
=============================================================*/
.tour-guide .guide ul li:not(:last-child) {
  margin-bottom: 24px;
}

/*===========================================================
08: dark-mode CSS
=============================================================*/
.dark-mode .info ul a img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(322deg) brightness(102%) contrast(102%);
}

.dark-mode .info h3,
.dark-mode .place-card .content h4,
.dark-mode .home .title h2,
.dark-mode .place-card .content .price h3,
.dark-mode .guide .item .content h4,
.dark-mode .budget .content h4,
.dark-mode .locationModal .content h4,
.dark-mode .budget .content .price span {
  color: white;
}

.dark-mode .serviceModal .item .image,
.dark-mode .service .item .image {
  background: var(--gray-90);
}

.dark-mode .locationModal .content .location,
.dark-mode .guide .item .content .location {
  color: var(--secondary-text);
}

.dark-mode .locationModal .content .location img,
.dark-mode .guide .item .content .location img {
  filter: brightness(0) saturate(100%) invert(53%) sepia(9%) saturate(375%) hue-rotate(164deg) brightness(94%) contrast(92%);
}