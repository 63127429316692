.horizontal-line {
    width: 100%;
    border: none;
    border-top: 1px solid rgb(192 192 192);
    margin: 2px 0;
}

/* Switch.css */
.switch-container {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: background-color 0.4s, transform 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.4s;
}

input:checked+.slider {
    background-color: #198754;
}

input:checked+.slider:before {
    transform: translateX(26px);
}