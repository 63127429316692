input {
    margin-bottom: 10px;
}

p {
    margin-bottom: 0 !important;
}

/* Add this CSS to your stylesheet */
.date-input {
    background-image: url('../images/details/calender-icon.png');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px 20px;
    padding-right: 30px;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
}

.date-input::-webkit-calendar-picker-indicator {
    opacity: 0;
}

.date-input::-ms-expand {
    display: none;
}

/* 
body {
    background-color: #eafff2;
} */

/* .mainBackground {
    background-color: #eafff2;
    height: 100%;
} */

.pageHead {
    background-color: #0E795D;
    padding: 16px 24px 10px 24px;
    margin-top: 0;
    margin-bottom: 20px;
}

.mainTitleLight {
    color: white !important;
}

.white_button {
    background: white;
    border-radius: 100px;
    height: 45px;
    width: 45px;
}

/* .main_a_tag {
    border: 1px solid #e3e3e3;
    margin: 4px 3px;
    padding: 10px;
    box-shadow: 2px 2px 4px 0px gray;
}

.a_tag_img {
    margin-right: 8px;
} */



.settings_main {
    margin: 20px 20px 5px 20px;
}

.settings_main p {
    font-size: 19px;
}

.settings_main_p {
    margin-bottom: 20px !important;
}

.settings_point {
    border: 1px solid #0E795D;
    padding: 15px 8px;
    border-radius: 10px;
    text-align: center;
}

/* Loader.css */
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
}

.spinner {
    border: 16px solid #eafff2;
    /* Light grey */
    border-top: 16px solid #0E795D;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* .query_list {
    background-color: #acf0d1;
    padding: 10px 20px;
} */

.query_list:hover {
    background-color: darkgreen;
    color: white;
    /* Optional: Change text color for better visibility */
}

.venue-tabs-container {
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 20px;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    padding: 10px 0;
    /* Add padding for better appearance */
}

.venue-tabs-container::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.venue-tabs {
    display: flex;
    gap: 5px;
}

.venue-button-container {
    flex: none;
}

.venue-button {
    margin: 0px 2px 15px 10px;
    background-color: transparent;
    border: 1px solid #0e795d;
    color: #0e795d;
    padding: 5px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 25px;
    transition: background-color 0.3s ease;
}

.venue-button.active {
    background-color: #0e795d;
    color: white;
}

.venue-button:focus {
    outline: none;
}

.venue-button-container a {
    text-decoration: none;
}

.venue-button-container a:focus {
    outline: none;
}

.reject_booking {
    border: 1px solid red;
    color: red;
    padding: 1px 5px;
    border-radius: 5px;

}

.edit_event {
    border: 1px solid #065e1c;
    color: #065e1c;
    padding: 1px 5px;
    border-radius: 5px;
    font-size: 12px;
}

.dropdown-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 1000;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
}

.dropdown-menu div {
    padding: 8px 16px;
}

.dropdown-menu div label {
    margin-left: 8px;
}

.dropdown-menu button {
    display: block;
    margin: 10px auto;
    padding: 8px 16px;
    background-color: #0e795d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.dropdown-menu button:hover {
    background-color: #0e795d;
}

/* Add these styles to your CSS file */
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
    display: none;
}

input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    padding-right: 1px;
    /* Adjust padding to make room for calendar icon */
}

.venue-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #e3e3e3;
    margin: 10px;
    padding: 10px;
    box-shadow: 2px 2px 4px 0px gray;
    border-radius: 12px;
}

.venue-card img {
    width: 150px;
    height: 150px;
    border-radius: 12px;
}

.venue-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.venue-item {
    width: 48%;
    margin-bottom: 5px;
}

.date-list-container {
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px 0;
    margin-bottom: 20px;
}

.date-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.date-item {
    background-color: #effffb;
    border: 1px solid #0e795d;
    color: #0e795d;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    white-space: nowrap;
}

.date-item.selected {
    background-color: #0e795d;
    color: #eafff2;
    border: 1px solid #0e795d;
}

.slot-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 15px;
}

.another-chat {
    width: 100%;
}

.another-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: white;
    cursor: pointer;
}

.another-main.selected {
    background-color: #0e795d;
    color: #eafff2;
}

.list-chat-book {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.next-button {
    width: 90%;
    padding: 10px 20px;
    background-color: #0e795d;
    color: #eafff2;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    position: fixed;
    /* Fixed position */
    bottom: 20px;
    /* Distance from the bottom */
    left: 50%;
    /* Center horizontally */
    transform: translateX(-50%);
    /* Adjust for centering */
    z-index: 1000;
    /* Ensure it is above other content */
}