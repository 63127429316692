/*================================================
Template name: Travgo Travel Mobile App Template
Version: 1.0.0
Author: Website Stock       
Author url: https://themeforest.net/user/website_stock/portfolio  

[ Table of Contents ]

01: splash-screen CSS
02: onboarding CSS
03: dark-mode CSS

==================================================*/

/*===========================================================
01: splash-screen CSS
=============================================================*/
.spalsh-screen {
  background: var(--primary);
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.spalsh-screen .circle {
  background: var(--sky);
  height: 262px;
  width: 262px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spalsh-screen h1 {
  font-size: 48px;
  font-weight: 800;
  color: white;
}

.spalsh-screen p {
  font-size: 18px;
  color: white;
}

.loader-spinner {
  display: inline-block;
  position: relative;
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: 77px;
  left: 50%;
  transform: translateX(-50%);
}

.loader-spinner div {
  animation: loader-spinner 1.2s linear infinite;
  transform-origin: 30px 30px;
}

.loader-spinner div:after {
  display: block;
  position: absolute;
  top: 10px;
  left: 29px;
  border-radius: 25%;
  content: " ";
  height: 6px;
  width: 4px;
  background: white;
}

.loader-spinner div:nth-child(1) {
  animation-delay: -1.1s;
  transform: rotate(0deg);
}

.loader-spinner div:nth-child(2) {
  animation-delay: -1s;
  transform: rotate(30deg);
}

.loader-spinner div:nth-child(3) {
  animation-delay: -0.9s;
  transform: rotate(60deg);
}

.loader-spinner div:nth-child(4) {
  animation-delay: -0.8s;
  transform: rotate(90deg);
}

.loader-spinner div:nth-child(5) {
  animation-delay: -0.7s;
  transform: rotate(120deg);
}

.loader-spinner div:nth-child(6) {
  animation-delay: -0.6s;
  transform: rotate(150deg);
}

.loader-spinner div:nth-child(7) {
  animation-delay: -0.5s;
  transform: rotate(180deg);
}

.loader-spinner div:nth-child(8) {
  animation-delay: -0.4s;
  transform: rotate(210deg);
}

.loader-spinner div:nth-child(9) {
  animation-delay: -0.3s;
  transform: rotate(240deg);
}

.loader-spinner div:nth-child(10) {
  animation-delay: -0.2s;
  transform: rotate(270deg);
}

.loader-spinner div:nth-child(11) {
  animation-delay: -0.1s;
  transform: rotate(300deg);
}

.loader-spinner div:nth-child(12) {
  animation-delay: 0s;
  transform: rotate(330deg);
}

/*===========================================================
02: onboarding CSS
=============================================================*/
.onboarding .image {
  clip-path: circle(100% at 50% 11%);
}

.onboarding .content {
  padding: 32px 24px 24px 24px;
}

.onboarding .content h2 {
  color: var(--primary-text);
  font-size: 24px;
  font-weight: 700;
}

.onboarding .content p {
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 500;
  padding-top: 16px;
}

.onboarding .content .btn-get-started {
  margin-top: 32px;
  margin-bottom: 24px;
}

.onboarding .content h6 {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-text);
}

.onboarding .content h6 a {
  color: var(--primary);
}

.onboarding .btn-primary {
  font-size: 18px;
}

.onboarding .slider-footer {
  padding: 0 24px 32px 24px;
  margin-top: 40px;
}

.onboarding .slider-footer.hide {
  display: none;
}

.onboarding .slider-footer button {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--gray-60);
  font-size: 14px;
  font-weight: 600;
}

.onboarding .swiper-pagination {
  position: static;
  text-align: right;
}

/*===========================================================
03: dark-mode CSS
=============================================================*/
.dark-mode .onboarding .content h6,
.dark-mode .auth h6,
.dark-mode .onboarding .content h2 {
  color: white;
}

.dark-mode .auth .auth-form {
  background: var(--gray-100);
}

.dark-mode .auth .social-btn.apple {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(322deg) brightness(102%) contrast(102%);
}

