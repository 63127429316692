* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h2 {
    font-size: 30px;
    font-family: "work sans", sans-serif;
}

p {
    font-size: 24px;
    /* margin: 1rem 0 2.4rem 0; */
    /* line-height: 1.8rem; */
    font-weight: 400;
    font-family: "source serif pro", serif;
}

.model-btn {
    padding: 10px 10px;
    font-size: 15px;
    border: none;
    border-radius: 0.3rem;
    background-color: #108700;
    color: #fff;
    cursor: pointer;
    font-family: "work sans", sans-serif;
    transform: scale(0.9);
}

.model-btn:hover {
    background-color: #fff;
    color: #212121;
    border: #212121 solid 1px;

}


.container {
    margin: 5rem;
}

.modal-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(189, 189, 189, 0.9);

}

.modal-container {
    position: fixed;
    border-radius: 10px;
    padding: 20px;
    top: 50%;
    left: 50%;
    background-color: #ffffff;
    transform: translate(-50%, -50%);
    width: 90%;
}