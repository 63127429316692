:root {
  --primary-color: rgb(115, 255, 0);
  /* Replace with your primary color */
  --primary-color-light: hsl(115, 100%, 93%);
  /* Lighter shade of primary color */
  --primary-color-dark: hsl(106, 100%, 36%);
  /* Darker shade of primary color */
}

.App {
  text-align: center;
}

.parent-container {
  padding: 10px;
}

.auth-header {
  position: relative;
  height: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;

}

.auth-header-bar {
  position: relative;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(15, 214, 15);
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;



}


.form-input {
  width: 95%;
  position: relative;
}


.form-control {

  position: relative;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  color: black;
  background-color: white;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
}


.theme-form .form-group .form-input .contact-img {
  position: absolute;
  top: 12px;
  right: 15px;
}

[dir=rtl] .theme-form .form-group .form-input .contact-img {
  left: 15px;
  right: unset;
}

.auth-content {
  margin-top: 10px;
  width: 100%;
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  margin-bottom: 10px;
  padding: 0 20px 40px;
}

.custom-container {

  padding: 20px;
}




.title-box {

  background: #fff;
  border-radius: 10px;


  width: 100%;
  border: 15px solid rgb(5, 20, 5);
  padding: 10px;
  border-radius: 10px;
  border: 30px;
  box-sizing: border-box;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15);
}


.btn {

  background: #0e795d;
  border: 1px solid #0e795d;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
}

.auth-form {

  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--white), 1);

  margin-top: -10px;
  padding-top: 35px;
  padding-bottom: 40px;
  position: relative;
  width: 100%;
}

/* .app-main {} */


.app-main-card {
  /* background-color: var(--secondary) !important; */

  background-color: #beecce !important;
  border-radius: 10px;
  padding: 10px;
  /* box-shadow: 2px 2px 5px gray; */
}

/* .app-main-header {} */


/* .app-main-highlight {} */


.back-arrow {

  border-radius: 20px;
  background-color: aliceblue;
}

/* .app-dark {} */

/* .app-light {} */

/* .app-lightbg {} */

/* .app-tempory {} */




.column {
  float: left;
  width: 25%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding */
.row {
  margin: 0 -5px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
}





.auth-form h2 {
  width: 90%;
  color: rgba(var(--dark-text), 1);
}

.auth-form .form-group {
  position: relative;
  display: block;
  margin-top: 20px;
}

.auth-form .form-group:first-child {
  margin-top: 0;
}

.auth-form .form-group .form-label {
  color: rgba(var(--dark-text), 1);
}

.auth-form .form-group h5 {
  color: rgba(var(--light-text), 1);
  margin-bottom: 20px;
  line-height: 1.2;
  font-size: 16px;
}

.auth-form .form-group .form-control {
  position: relative;
  padding: 12px;
  border-radius: 6px;
  border: none;
  color: rgba(var(--dark-text), 1);
  background-color: rgba(var(--box-bg), 0.1);
}

/*=================================
  Template name: Travgo Travel Mobile App Template
  Version: 1.0.0
  Author: Website Stock       
  Author url: https://themeforest.net/user/website_stock/portfolio  
  
  ===================================*/

/*===============================================
   common css 
  ================================================*/
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&amp;display=swap');

:root {
  --primary: #2196F3;
  --secondary: #F6F8FE;
  --primary-text: #111111;
  --secondary-text: #78828A;
  --sky: #3BA5FA;
  --success: #00C566;
  --alert: #E53935;
  --warning: #FACC15;
  --additional1: #6C6C6C;
  --additional2: #E3E7EC;
  --additional3: #F7F7F7;
  --additional4: #E9EBED;
  --additional5: #FF784B;
  --gray-10: #FDFDFD;
  --gray-20: #ECF1F6;
  --gray-30: #E3E9ED;
  --gray-40: #D1D8DD;
  --gray-50: #BFC6CC;
  --gray-60: #9CA4AB;
  --gray-70: #78828A;
  --gray-80: #66707A;
  --gray-90: #434E58;
  --gray-100: #171725;
}

/* scrollbar hide */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
  width: 0;
  scrollbar-width: none;
}

.scrollbar-hidden {
  scrollbar-width: none;
}

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
ol {
  margin: 0;
  padding: 0;
}

ul li,
ol li {
  list-style: none;
}

img,
svg {
  vertical-align: middle;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: 0;
}

.dz-clear:after {
  clear: both;
  display: block;
  content: '';
}

.dz-flex-style {
  display: flex;
  justify-content: space-between;
}

button {
  border: 0;
  cursor: pointer;
  text-align: center;
  background: transparent;
}

button:hover,
button:focus {
  text-decoration: none;
  outline: none;
}

.dz-icon-btn-design {
  padding-left: 16px;
}

.dz-ml-8 {
  margin-left: 8px;
}

.dz-mr-8 {
  margin-right: 8px;
}


/*===============================================
   preloader css 
  ================================================*/

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 99999;
}

.preloader img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  animation: mymove 2s linear infinite;
  -webkit-animation: mymove 2s linear infinite;
}


@keyframes mymove {
  0% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
    -webkit-transform: scale(1) translate(-50%, -50%);
    -moz-transform: scale(1) translate(-50%, -50%);
    -ms-transform: scale(1) translate(-50%, -50%);
    -o-transform: scale(1) translate(-50%, -50%);
  }

  50% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(0.8);
    -webkit-transform: translate(-50%, -50%) scale(0.8);
    -moz-transform: translate(-50%, -50%) scale(0.8);
    -ms-transform: translate(-50%, -50%) scale(0.8);
    -o-transform: translate(-50%, -50%) scale(0.8);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
  }
}

/*===============================================
   device css 
  ================================================*/
.device-iphone-x {
  height: 700px;
  width: 390px;
}

.device-iphone-x .device-frame {
  height: 700px;
  width: 390px;
  box-shadow: inset 0 0 2px 2px #c8cacb, inset 0 0 0 5px #e2e3e4;
  padding: 20px;
  border-radius: 50px;
}

.device-iphone-x .device-sensors,
.device-iphone-x .device-stripe,
.device-iphone-x .device-power,
.device-iphone-x .device-header,
.device-iphone-x .device-btns {
  display: none;
}

.device-ipad-pro .device-frame {
  background: black;
}

/*===============================================
   main css 
  ================================================*/
.dz-main .dz-qr-code {
  position: fixed;
  right: 30px;
  bottom: 10px;
  width: 200px;
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 10px 2px 95px hsla(0, 0%, 0%, 0.08);
  border: 1px solid var(--gray-20);
  background: white;
  z-index: 99;
}

.dz-main .dz-qr-code p {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  padding-top: 10px;
}

.dz-main .dz-qr-code img {
  width: 100%;
}

.dz-main {
  padding-top: 110px;
}

.dz-main h1 {
  font-size: 40px;
  color: var(--primary-text);
  padding-top: 20px;
  padding-bottom: 30px;
}

.dz-main p {
  font-size: 17px;
  line-height: 30px;
  color: var(--gray-60);
}

.dz-main .dz-feature {
  padding-top: 30px;
  padding-bottom: 30px;
}

.dz-main .dz-feature li {
  font-size: 18px;
  line-height: 36px;
  color: var(--primary-text);
  display: flex;
  align-items: center;
}

.dz-main .dz-feature li i {
  padding-right: 15px;
  color: var(--primary);
}

.dz-main #tab {
  display: none;
}

/* sidebar */
.dz-main .dz-sidebar {
  position: absolute;
  top: 70px;
  right: 30px;
  z-index: 99;
}

.dz-main .dz-buy,
.dz-main .dz-buttons li a {
  display: inline-block;
  line-height: 50px;
  width: 200px;
  text-align: center;
  font-size: 18px;
  color: white;
  text-transform: capitalize;
  background: var(--success);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.dz-main .dz-buttons li:not(:last-child) {
  padding-bottom: 20px;
}

.dz-main .dz-buttons li a {
  background: var(--primary);
}

.dz-main .dz-buy i,
.dz-main .dz-buttons li a i {
  padding-right: 10px;
}

.dz-main .dz-align {
  align-items: center;
}


/* feature */
.dz-all-feature {
  padding-top: 85px;
}

.dz-all-feature .dz-title {
  padding-bottom: 40px;
}

.dz-all-feature .dz-title h2 {
  text-align: center;
  font-size: 32px;
  color: var(--primary-text);
}

.dz-all-feature .dz-item {
  padding: 30px;
  margin: 10px;
  box-shadow: 10px 2px 95px hsla(0, 0%, 0%, 0.08);
}

.dz-all-feature .dz-item i {
  display: inline-block;
  text-align: center;
  color: white;
  font-size: 20px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  line-height: 40px;
  background: var(--primary);
}

.dz-all-feature .dz-item h3 {
  font-size: 20px;
  color: var(--primary-text);
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 10px;
}

.dz-all-feature .dz-item p {
  font-size: 16px;
  line-height: 26px;
  color: var(--gray-60);
}

/* footer */
.dz-footer {
  background: var(--primary);
  margin-top: 40px;
}

.dz-footer p {
  color: white;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
}

/* small-screen */
.dz-small-screen {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 100vh;
}

.dz-small-screen .dz-all {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  text-align: center;
}

.dz-small-screen h1 {
  font-size: 32px;
  color: var(--primary-text);
  text-transform: capitalize;
  padding-top: 20px;
}

.dz-small-screen p {
  font-size: 16px;
  color: var(--gray-70);
  padding-top: 10px;
  padding-bottom: 40px;
}

.dz-small-screen ul li {
  padding-top: 20px;
}

.dz-small-screen ul li a {
  display: block;
  width: 300px;
  text-align: center;
  line-height: 50px;
  border-radius: 10px;
  text-transform: capitalize;
  color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.dz-small-screen ul li:first-child a {
  background: var(--primary);
}

.dz-small-screen ul li:last-child a {
  background: var(--success);
}

.dz-small-screen ul li a i {
  padding-right: 10px;
}

/*===============================================
   responsive css 
  ================================================*/
@media (min-width: 1200px) and (max-width: 1400px) {
  .dz-main .dz-qr-code {
    right: auto;
    left: 30px;
  }

  .dz-main .dz-sidebar {
    top: 30px;
    right: 100px;
  }

  .dz-main .dz-buttons {
    display: flex;
  }

  .dz-main .dz-buttons li:first-child {
    padding-right: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .dz-main .dz-text-part {
    padding-bottom: 50px;
  }

  .dz-main .dz-sidebar {
    top: 100%;
  }
}

@media (min-width: 280px) and (max-width: 991px) {
  .dz-small-screen {
    display: block;
  }

  section.dz-big-screen {
    display: none !important;
  }
}

.iconsize {
  width: 40px;
}



.vocherdiscount {
  width: 190px;
  height: 120px;
}


.bottom-border-radious {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.top-border-radious {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: -25px;
}