.cardslots {
    margin-top: 50px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cardslots-headerslots {
    text-align: center;
    background-color: #f8f9fa;
    border-bottom: none;
}

.cardslots-bodyslots {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.timeslots {
    margin-bottom: 10px;
}

.cardslots-footerslots {
    background-color: #f8f9fa;
    border-top: none;
    text-align: center;
}

.btnslots-customslots {
    /* Add your custom button styles here */
    background-color: #0e795d;
    /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
}



.mlslots-autoslots {
    margin-left: auto;
    /* Use margin-left: auto to push the button to the right */
}

.time-section {
    display: flex;
    justify-content: space-between;
}

.time-info p {
    margin: 0;
    font-size: 12px !important;
    font-family: sans-serif;

}

.time-info p span {
    margin: 0px 2px 5px 10px;
    background-color: transparent;
    border: 1px solid #0e795d;
    color: #0e795d;
    padding: 1px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.venue-button {
    margin: 0px 2px 15px 10px;
    background-color: transparent;
    border: 1px solid #0e795d;
    color: #0e795d;
    padding: 5px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 25px;
    transition: background-color 0.3s ease;
}