.venues-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.venue-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 10px;
    /* padding: 20px; */
    padding: 20px 20px 10px 20px;
    width: 170px;
    position: relative;
    transition: transform 0.3s ease-in-out;
}

.venue-card:hover {
    transform: translateY(-5px);
}

.venue-name {
    top: 8px;
    font-size: 18px;
    font-weight: bold;
}

.venue-location {
    font-size: 14px;
    display: block;
    /* Display venue location on a separate line */
    margin-top: 5px;
}

.remove-button {
    position: absolute;
    top: 2px;
    right: 5px;
    background: none;
    border: none;
    cursor: pointer;
}

.remove-button img {
    width: 20px;
    height: 20px;
}

.venue-image {
    font-size: 14px;
    color: #333;
    /* You can adjust the color as needed */
    margin-top: 5px;
    /* Adjust margin as needed */
    display: block;
    /* Ensures each venue image is displayed on a new line */
}