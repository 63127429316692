.list-container {
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-container h4,
.list-container p,
.list-container span {
    margin: 0;
}


.vendor-container {
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vendor-container h4,
.vendor-container p {
    margin: 0;
}

.booking-request-container {
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.booking-request-container span {
    margin: 0;
    margin-left: 5px;
}

.hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.properties {
    border: 1px solid #0e795d;
    color: #0e795d;
    padding: 5px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}


.horizontal-line {
    width: 100%;
    border: none;
    border-top: 1px solid rgb(148, 147, 147);
    margin: 20px 0;
}

.status_head_btn {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: start;
    grid-gap: 10px;
}

.hori_btn_line {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin: 0 0;
}



.status-button-container {
    display: flex;
    justify-content: flex-start;
    margin: 0 10px;
}


.status-button {
    margin: 0px 2px 0 10px;
    background-color: transparent;
    border-bottom: 1px solid #0e795d;
    color: #0e795d;
    padding: 5px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.status-button:hover {
    background-color: #0e795d;
    color: white;
}

.venue_head {
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: start;
}

.venue-button {
    margin: 0px 2px 15px 10px;
    background-color: transparent;
    border: 1px solid #0e795d;
    color: #0e795d;
    padding: 5px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 25px;
    transition: background-color 0.3s ease;
}


.mont_center {
    text-align: center;
    font-size: 16px;
}

.slotsView {
    border: 2px solid #e9e9e9;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 5px;
}

.slotsView p {
    color: rgb(117, 117, 117);
    margin-right: 35px;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px !important;
    font-size: 15px;
    padding: auto;
}

.slotsView p span {
    margin-right: 10px;
}