/*================================================
Template name: Travgo Travel Mobile App Template
Version: 1.0.0
Author: Website Stock       
Author url: https://themeforest.net/user/website_stock/portfolio  

[ Table of Contents ]

01: details CSS
  1.1: page-title
  1.2: details-body
  1.3: details-title
  1.4: details-footer
02: vacation details CSS
  2.1: details-info
  2.2: details location
03: hotel details CSS
04: dark-mode CSS

==================================================*/
/*===========================================================
01: details CSS
=============================================================*/

/* 1.1: page-title */
.details .page-title {
  position: absolute;
  background: transparent;
}

.details .page-title .main-title {
  color: white;
}

.details .page-title .back-btn {
  background: rgba(255, 255, 255, 0.08);
}

.details .page-title .back-btn img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(139deg) brightness(103%) contrast(101%);
}

/* 1.2: details-body */
.details .details-body {
  background: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 30px 24px 24px 24px;
  margin-top: -50px;
  position: relative;
  z-index: 5;
}

/* 1.3: details-title */
.details .details-title h3 {
  font-size: 24px;
  font-weight: 700;
  color: var(--primary-text);
  padding-bottom: 8px;
}

.details .details-title ul p {
  font-size: 12px;
  font-weight: 600;
  color: var(--gray-90);
}

.details .details-title ul p span {
  color: var(--warning);
  background: transparent;
}

.details .details-title span {
  height: 40px;
  width: 40px;
  background: var(--additional3);
}

/* 1.4: details-footer */
.details .details-footer {
  padding: 22px 24px;
  position: sticky;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 10;
}

.details .details-footer p {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-text);
}

.details .details-footer p span {
  font-size: 16px;
  font-weight: 500;
  color: var(--secondary-text);
}

.details .details-footer button,
.details .details-footer a {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background: var(--primary);
  padding: 12px 50px;
  border-radius: 20px;
}

/*===========================================================
02: vacation details CSS
=============================================================*/
/* 2.1: details-info */
.details-info p {
  font-size: 14px;
  line-height: 28px;
  color: var(--primary-text);
}

.reviews>div:not(:last-child) {
  margin-bottom: 24px;
}

.reviews .review-card .image {
  height: 45px;
  width: 45px;
}

.reviews .review-card .review-image {
  height: 67px;
  width: 67px;
}

.reviews .review-card h4 {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-text);
  padding-bottom: 8px;
}

.reviews .review-card span {
  font-size: 14px;
  color: var(--gray-60);
}

.reviews .review-card p {
  padding-top: 16px;
  color: var(--primary-text);
  font-size: 14px;
  line-height: 22px;
}

/* 2.2: details location */
.details-location .map {
  width: 100%;
  height: 156px;
}

.details-location iframe {
  width: 100%;
  height: 156px;
}

/*===========================================================
03: hotel details CSS
=============================================================*/

.hotel-details .details-footer p span {
  font-size: 14px;
  font-weight: 600;
  color: var(--alert);
}

.details-info p button {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary);
}

/*===========================================================
04: dark-mode CSS
=============================================================*/
.dark-mode .details .details-body,
.dark-mode .details .details-footer {
  background: var(--gray-100);
}

.dark-mode .details-body .title h4,
.dark-mode .details .details-title h3,
.dark-mode .details-info p,
.dark-mode .guide .item .content h4,
.dark-mode .reviews .review-card h4,
.dark-mode .reviews .review-card p,
.dark-mode .details .details-footer p {
  color: white;
}

.dark-mode .details .details-title ul p,
.dark-mode .guide .item .content .location {
  color: var(--secondary-text);
}

.dark-mode .guide .item .content .location img{
  filter: brightness(0) saturate(100%) invert(53%) sepia(9%) saturate(375%) hue-rotate(164deg) brightness(94%) contrast(92%);
}

.dark-mode .guide .item .rating{
  background: var(--gray-80);
}